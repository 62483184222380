<template>
  <div class="container">
    <poster :poster-list="posterList" :page-path="pagePath" :module-key="moduleKey" :token="token" :time-format="3" />
  </div>
</template>

<script>
import Poster from '../../../components/Poster/Index'

export default {
  name: "NewYearPoster",
  components: {
    Poster
  },
  data() {
    return {
      token: this.$route.query.token,
      posterList: [
   
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20220331135904528.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20220331135703424.png',
          textColor: '#fff',
          tempPoster: '',
          textPoint: [429, 1194],
          wxCode: { width: 188, point: [58, 1120] }
        },
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20220331135916592.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20220331135722424.png',
          textColor: '#fff',
          tempPoster: '',
          textPoint: [429, 1194],
          wxCode: { width: 188, point: [58, 1120] }
        },
      ],
      pagePath: this.baseHost + '/',
      moduleKey: ''
    }
  },
  created() {
    this.moduleKey = this.token ? 'b337a68b-0ffb-4d97-bdd4-9300146fa320' : 'gzhDJKfour20220407'
  }
}
</script>

<style scoped>
</style>